<template>
  <div ref="chatView" class="hello">
    <el-scrollbar ref="myScrollbar" :class="[showSize ? 'chatView' : 'chatView_mobile']">
      <div>
        <div v-for="item in chatList" :key="item.index">
          <div v-if="item.role === 'user'" :class="[showSize ? 'user' : 'user_mobile']">
            <div :class="[showSize ? 'picture' : 'picture_mobile']">用户</div>
            <!-- {{ item.input }} -->
            <!-- <div v-html="item.input"></div> -->
            <span :class="[showSize ? 'content_box' : 'content-box_mobile']">
              <div :class="[showSize ? 'content' : 'content_mobile']" v-html="item.input"></div>
            </span>
            <div style="clear:both"></div>
            <!-- <span class="speak">
          </span> -->
            <!-- 啊的发生飞洒发生发生的两份简历咖世家的你好啊的发生飞洒发生发生的两份简历咖世家的你好啊的发生飞洒发生发生的两份简历咖世家的你好啊的发生飞洒发生发生的两份简历咖世家的你好啊的发生飞洒发生发生的两份简历咖世家的你好啊的发生飞洒发生发生的两份简历咖世家的你好啊的发生飞洒发生发生的两份简历咖世家的你好啊的发生飞洒发生发生的两份简历咖世家的你好啊的发生飞洒发生发生的两份简历咖世家的你好啊的发生飞洒发生发生的两份简历咖世家的你好
         -->
          </div>
          <div v-if="item.role === 'gpt'" :class="[showSize ? 'gpt' : 'gpt_mobile']">
            <div :class="[showSize ? 'picture' : 'picture_mobile']">GPT</div>
            <span :class="[showSize ? 'content_box' : 'content_box_mobile']">
              <div :class="[showSize ? 'content' : 'content_mobile']" v-html="item.input"></div>
            </span>
            <div style="clear:both"></div>
          </div>
        </div>
      </div>
    </el-scrollbar>
    <div>


    </div>
    <div :class="[showSize ? 'input' : 'input_mobile']" @keyup.enter="inputText()">
      <!-- <el-input :class="[showSize ? 'input_box' : 'input_box_mobile']" v-model="input" resize="false"
        :autosize="{ minRows: 2, maxRows: 6 }" placeholder="请输入内容">
        <template #suffix>
          <span :class="[showSize ? 'input_button' : 'input_button_mobile']"><i @click="inputText()"
              class="el-icon-position"></i></span>
        </template></el-input> -->
      <!-- <div class="input-box">
        <scrollbar style="  border: 1px solid #ccc;width:640px;height:50px;overflow-y: hidden;">
          <div @keyup.enter="inputText()" style="min-height:50px;text-align: left;width:640px;" contenteditable="true">
          </div>
        </scrollbar>
      </div> -->
      <div>
        <el-input :class="[showSize ? 'input_box' : 'input_box_mobile']" v-model="input" type="textarea"
          :autosize="{ minRows: 1, maxRows: 2.5 }" placeholder="请输入内容" :append="appendNode">
        </el-input>
        <span :class="[showSize ? 'input_button' : 'input_button_mobile']"><i @click="inputText()"
            class="el-icon-position"></i></span>
      </div>


    </div>

  </div>
</template>

<script>
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
var MarkdownIt = require('markdown-it')

var md = new MarkdownIt();
import { h } from 'vue'
export default {
  name: 'ChatView',
  props: {
    msg: String
  },
  data() {
    return {
      chatList: [],
      input: '',
      chat_id: "",
      ws: null,
      cur_text: "",
      first_text: true,
      answer_end: true,
      ws_on: false,
      ping_inter: null
    }
  },
  created() {
    var userAgent = navigator.userAgent;
    // 判断是否为手机浏览器
    if (userAgent.indexOf("Mobile") > -1 || userAgent.indexOf("Android") > -1 || userAgent.indexOf("iPhone") > -1 || userAgent.indexOf("iPad") > -1) {
      // 是手机浏览器
      this.showSize = false
    } else {
      // 是PC浏览器
      // do something...
      this.showSize = true
    }
    // this.showSize = window.innerHeight > 670 ? true : false
  },
  destroyed() {
    this.ws.close()
    clearInterval(this.ping_inter)
  },
  computed: {
    appendNode() {
      return h('el-button', { onClick: this.inputText }, '点击按钮')
    }
  },
  mounted() {
    let that = this
    // 生成uuid
    this.chat_id = uuidv4()
    console.log("chat_id: ", this.chat_id)
    // this.$refs['myScrollbar'].scrollTop = 100; // 设置scrollTop值
    // this.$nextTick(() => {
    //   console.log(this.$refs['myScrollbar'].scrollTop); // 验证scrollTop值是否生效
    // });

    // 连接websocket
    // this.ws = new WebSocket("/ws");
    // this.ws = new WebSocket("ws://localhost/socket")
    
    const { protocol, host } = location
    const url = `${protocol === 'https' ? 'wss' : 'ws'}://${host}/socket`
    this.ws = new WebSocket(url)

    console.log("location.host: ", location.host)

    this.ws.onopen = function () {
      console.log("连接成功");
      that.ws_on = true

      // 发送chat_id
      // message = { "chat_id": that.chat_id }
      // that.ws.send(message)
      
      // 设置心跳包
      that.ping_inter = setInterval(function () {
        that.ws.send('@-pingping-@');
      }, 3000)
    };
    this.ws.onmessage = function (e) {
      if (e.data == "@-endAnswer-@") {
        that.answer_end = true
        that.scrollDown()
        return
      }
      that.answer_end = false
      if (that.first_text) {
        that.cur_text = e.data
        that.first_text = false
        const gpt = { role: 'gpt', input: md.render(that.cur_text) }
        that.chatList.push(gpt)
      } else {
        that.cur_text = that.cur_text + e.data
        const gpt = { role: 'gpt', input: md.render(that.cur_text) }
        that.chatList.pop()
        that.chatList.push(gpt)
      }

    };
    this.ws.onclose = function () {
      console.log("连接关闭");
      that.ws_on = false
    };
    this.ws.onerror = function () {
      console.log("连接异常");
      that.ws_on = false
    };

  },
  methods: {
    add() {

    },
    scrollDown() {
      this.$nextTick(() => {
        document.documentElement.scrollTop = this.$refs['chatView'].scrollHeight
      });

    },
    inputText() {
      if (this.input == "") {
        return
      }
      if (this.ws_on == false) {
        // 使用elmentui提示用户等待
        this.$message({
          message: '请等待连接成功',
          type: 'warning'
        });
        return
      }
      if (this.answer_end == false) {
        // 使用elmentui提示用户等待
        this.$message({
          message: '请等待回答结束',
          type: 'warning'
        });
        return
      }
      const user = { role: 'user', input: md.render(this.input) }
      this.chatList.push(user)
      this.input = ''
      this.cur_text = ""
      this.first_text = true
      this.scrollDown()
      this.getApi(user.input)

    },
    async getApi(question) {
      // await axios.post("/api/chat", {
      //   "question": question,
      //   "chat_id": this.chat_id
      // }).then(res => {
      //   console.log(res)
      //   const gpt = { role: 'gpt', input: marked(res.data.answer, options) }
      //   this.chatList.push(gpt)
      // }).catch(err => {
      //   console.log(err)
      // })
      this.ws.send(question);
      this.scrollDown()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chatView {
  border-bottom: 1px solid rgb(212, 212, 212);
  margin-bottom: 200px;
}

.chatView_mobile {
  border-bottom: 1px solid rgb(212, 212, 212);
  margin-bottom: 100px;
}

.hello {
  display: block;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%
}

.user {
  background-color: rgb(247, 247, 247);
  height: auto;
  min-height: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 200px;
  padding-right: 200px;
  font-size: 18px;
  text-align: left;
  border-top: 1px solid rgb(212, 212, 212);
}

.input-box {
  width: 640px;
  position: relative;
  top: 50px;
  height: 80px;
}

.input-box:focus {
  outline: none;
  border-color: rgb(195, 231, 255);
}

.content_box {
  width: 93%;
  float: left;
}

.content_box_mobile {
  width: 81%;
  float: left;
}

.user_mobile {
  background-color: rgb(247, 247, 247);
  height: auto;
  min-height: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  text-align: left;
  border-top: 1px solid rgb(212, 212, 212);
}

.user>.picture {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background-color: #6eee83;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  float: left;
  position: relative;
  margin-right: 30px;
  /* margin-bottom: calc((100% - 55px) / 2); */
}

.user_mobile>.picture_mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 15px;
  border-radius: 10px;
  background-color: #6eee83;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  float: left;
  position: relative;
  margin-right: 15px;
  /* margin-bottom: calc((100% - 55px) / 2); */
}

.content {}

.content_mobile {
  font-size: 12px;
}

.gpt>.picture {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background-color: #c0c0c0;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  float: left;
  position: relative;
  margin-right: 30px;
  /* margin-bottom: calc((100% - 55px) / 2); */
}

.gpt_mobile>.picture_mobile {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  font-size: 15px;
  border-radius: 10px;
  background-color: #c0c0c0;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  float: left;
  position: relative;
  margin-right: 15px;
  /* margin-bottom: calc((100% - 55px) / 2); */
}

.gpt {
  background-color: white;
  height: auto;
  min-height: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 200px;
  padding-right: 200px;
  font-size: 18px;
  text-align: left;
  border-top: 1px solid rgb(212, 212, 212);
}

.gpt_mobile {
  background-color: white;
  height: auto;
  min-height: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 18px;
  text-align: left;
  border-top: 1px solid rgb(212, 212, 212);
}

.speak {
  float: left;
  max-width: 1000px;
  min-width: 300px;
  height: auto;
}

.input {
  position: fixed;
  bottom: 50px;
  width: 100%;
  /* width: 3000px; */
  height: 150px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  z-index: 1000;
  bottom: 0px;
  text-align: center;
}

.input_mobile {
  position: fixed;
  width: 100%;
  /* width: 3000px; */
  height: 110px;
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(2px);
  bottom: 0px;
  text-align: center;
}

.input_box {
  width: 640px;
  position: relative;
  top: 50px;
  height: auto;
  font-size: 16px;
  border: 1px solid #DCDFE6;
}

.input_box_mobile {
  width: 300px;
  position: relative;
  top: 40px;
  border: 1px solid #DCDFE6;
}

:deep(.input_box .el-textarea__inner) {
  display: block;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 600px;
  font-size: inherit;
  color: #606266;
  background-color: #FFF;
  background-image: none;
  border: 0px solid #DCDFE6;
  float: left;
  resize: none;
  /* border-radius: 4px; */
  /* transition: border-color .2s cubic-bezier(.645,.045,.355,1); */
}

:deep(.input_box_mobile .el-textarea__inner) {
  display: block;
  padding: 5px 15px;
  line-height: 1.2;
  box-sizing: border-box;
  width: 275px;
  font-size: inherit;
  color: #606266;
  background-color: #FFF;
  background-image: none;
  border: 0px solid #DCDFE6;
  resize: none;
  float: left;
  /* border-radius: 4px; */
  /* transition: border-color .2s cubic-bezier(.645,.045,.355,1); */
}

.input_button {
  font-size: 30px;
  cursor: pointer;
  position: relative;
  top: 50px;
  left: -35px;
  color: #494949;
}

.input_button_mobile {
  font-size: 25px;
  cursor: pointer;
  position: relative;
  top: 43px;
  left: -30px;
  color: #494949;
}

:deep(.el-input__inner) {}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
